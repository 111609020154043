import { useContext } from 'react';
import { Flex, HStack} from '@chakra-ui/react';

import AppContext from '../AppContext';

import Brand from './Brand'
import ExternalNavlink from './ExternalNavlink';
import Navlink from './Navlink';

export default function Navbar() {
    const { pages } = useContext(AppContext);
    return (
        <Flex align = "center" as = "nav" p = {4} bg = "blue.500" color = "white">
            <Brand />
            <HStack ml = "auto">
                {pages.map(page => <Navlink key = {page.title} {...page} />)}
                <ExternalNavlink text = "Course" href = "https://celloben.com/course" />
                <ExternalNavlink text = "Cello Ben" href = "https://celloben.com" />
            </HStack>
        </Flex>
    )
}


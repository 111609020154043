import { Box, Heading, Text } from '@chakra-ui/react';

import Navbar from './Navbar';

export default function About() {
    return (
        <div className = "container">
            <Navbar />
            <Box m = {8}>
                <Heading align = "center">About Ben</Heading>
                <Text p = {4}>
                    Cellist Ben Fryxell is a seasoned veteran of the concert stage, at home as a soloist, orchestral player, and chamber musician. Since 2022, he has served as Associate Principal Cello of the Jacksonville Symphony, where he was granted tenure less than a year after joining. Before that, he was a Fellow with the New World Symphony, where he played as a principal cellist under conductors such as Michael Tilson Thomas, HK Gruber, and Susanna Mälkki. He performed with New World and conductor Matthias Pintscher as a soloist in 2022, playing Bernd Alois Zimmermann's "Canto di Speranza".
                </Text>
                <Text p = {4}>
                    Ben holds a special interest and strong skillset in contemporary music, and loves how many new sound worlds we can be exposed to by composers of our time. He was invited to be a New Fromm Player at the Tanglewood Music Center in 2022, where he was re-engaged the following summer. During his time in that role, he worked closely with composers such as Reena Esmail, George Lewis, Nico Muhly, Joan Tower, and many others.
                </Text>
                <Text p = {4}>
                    Ben is also a passionate educator. In addition to teaching private cello lessons, he has also been sought out as a sectional coach, as well as a music theory pedagogue. He received his Bachelor of Music from the Juilliard School, where he studied with Natasha Brofsky, and worked for the Music Theory and Analysis Department as a Teaching Assistant and Tutor. He then studied at the New England Conservatory of Music for his Master of Music degree, where he was a pupil of Yeesun Kim. He held another teaching assistantship there, this time working as a keyboard harmony teacher.
                </Text>
                <Text p = {4}>
                    In addition to being a cellist, Ben is a skilled pianist, conductor, composer, and arranger. He splits his time between Jacksonville, FL and the Chicago metropolitan area.
                </Text>
            </Box>
        </div>
    );
};
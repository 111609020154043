import { useContext } from 'react';
import { Box, Grid, Spinner } from '@chakra-ui/react';

import AppContext from '../AppContext';

import ConcertCard from './ConcertCard';

export default function UpcomingConcerts() {
    const { concerts } = useContext(AppContext);

    return (
        <div className = "container">
            {concerts.length > 0 ?
            <Grid templateColumns="repeat(auto-fill, minmax(280px, 1fr))" gap={4}>
                {concerts.map(concert => (
                    <ConcertCard key = {concert.id} {...concert} />
                ))}
            </Grid>
            : <Box mx = "auto">
                <Spinner />
            </Box>
            }
        </div>
    );
};
import { Box, Heading, Text } from '@chakra-ui/react';

import Navbar from './Navbar';

export default function ThankYou() {
    return (
        <div className = "container">
            <Navbar />
            <Box m = {8}>
            <Heading align = "center">Message Sent!</Heading>
            <Text m = {4}>
                Thank you! I'll be in touch shortly.
            </Text>
            </Box>
        </div>
    );
};
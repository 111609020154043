import { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Heading, Input, useToast, Textarea } from '@chakra-ui/react';

import axios from 'axios';

import Navbar from './Navbar';

export default function Contact() {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const toast = useToast();

    const handleSubmit = async() => {
        toast.closeAll();
        if(email === '' || message === '') {
            toast({
                status: 'error',
                title: 'Please fill in all required fields.',
                duration: '3000',
                isClosable: true,
                
            });
            return;
        }
        try {
            await axios.post('https://public.herotofu.com/v1/ffab6c10-7090-11ee-80c8-136eb968d513', {name, email, message});
            toast({
                status: 'success',
                title: 'Sent!',
                duration: '3000',
                isClosable: true,
                
            });
            setName('');
            setEmail('');
            setMessage('');
        } catch(e) {
            toast({
                status: 'error',
                title: 'Error. Please try again later.',
                duration: '3000',
                isClosable: true
            });
        }
    };

    return (
        <div className = "container">
            <Navbar />
            <Box m = {8}>
            <Heading align = "center">Get In Touch</Heading>
                <Heading size = "md" mb = {4}>
                    I welcome your emails! Please get in touch below.
                </Heading>
                <Box maxW = "800px">
                <FormControl isRequired = {false}>
                <FormLabel>Name</FormLabel>
                <Input type = "text" value = {name} onChange = {(e) => setName(e.target.value)} mb = {4} />
                </FormControl>
                <FormControl isRequired = {true}>
                <FormLabel>Email</FormLabel>
                <Input type = "text" value = {email} onChange = {(e) => setEmail(e.target.value)} mb = {4} />
                </FormControl>
                <FormControl isRequired = {true}>
                <FormLabel>Message</FormLabel>
                <Textarea value = {message} isRequired = {true} onChange = {(e) => setMessage(e.target.value)} mb = {4} />
                </FormControl>
                <Button onClick = {() => handleSubmit()}>Send</Button>
                </Box>
            </Box>
        </div>
    );
};
import { Box, Heading, Link, Text } from '@chakra-ui/react';

import Navbar from './Navbar';
import PricingTable from './PricingTable';

export default function Teaching() {
    return (
        <div className = "container">
            <Navbar />
            <Box m = {8}>
                <Heading align = "center">Teaching</Heading>
                <Text p = {4}>
                    Teaching cello is one of my great passions, and I've spent a LOT of time doing it, reflecting on it, and honing my skills as an educator. I've taught extensively in both English and Spanish, in person and online, one-on-one and in the classroom, cello and keyboard harmony and music theory. I'm currently taking on private students, both for regular study and for one-off/occasional lessons over Zoom.
                </Text>
                <Text p = {4}>
                    Much of my recent teaching has been online, and I've gained the skills to be able to work effectively with students even if we're in completely different parts of the world from one another. My beliefs on teaching are relatively straightforward, and globally, it boils down to one thing: observation. That means not only taking care to spot areas where students can improve, of course, but also listening to every student, and figuring out what their needs are. How do they learn best? What can I do for them to be the most effective teacher? What can we work on together to make sure that their passion for music continues to thrive?
                </Text>
                <Text p = {4}>
                    I also want the guidance that I provide to be available to you in perpetuity. As such, with your permission, I will record all of your lessons and make sure that you get the video files. I will also ask that after each lesson, you go back and watch the video before getting to work, and take notes...summarize what I've suggested and what we've discussed in your own words. This is a great way to help assimilate knowledge.
                </Text>
                <Text p = {4}>
                    And the last thing I'll mention here that's also crucial: for younger students, parental/guardian involvement is critical. Being a partner in your child's learning process is a wonderful gift that you can give them. So I ask that you be engaged with their work to the best of your ability.
                </Text>
                <Text p = {4}>
                    If you'd like to get a feel for my teaching, I would love for you to check out <Link href = "https://www.youtube.com/watch?v=4VIopSy7PW8&list=PL1RqL6Vygz3OB0OyMpH68-MaZZJblQd52" target = "_blank" color = "teal.500" textDecorationLine = "underline">this YouTube playlist of online "masterclasses"</Link> that I gave in 2020.
                </Text>
                <Text p = {4}>
                    For private teaching, my rates (in USD) are as follows:*
                </Text>
                <PricingTable />
                <Text p = {4}>
                    While music theory/keyboard harmony lessons are only available in English, cello lessons are available in English, Spanish, and German.
                </Text>
                <Text p = {4}>
                    If you're not ready to start private lessons yet, but you're an adult who wants to start learning cello, even if you don't have any musical training at all, I cordially invite you to <Link href = "https://www.udemy.com/course/cello-for-adult-beginners/?referralCode=6A7FC9C114C7E14FA705" target = "_blank" color = "teal.500" textDecorationLine = "underline">check out my online course here</Link>. I truly believe that you'll get a lot out of it, and it comes with a 30-day money-back guarantee.
                </Text>
                <Text p = {4}>
                    *I am a firm believer in the need for accessible education. I recognize that money is tight for many people and families. That should not be a barrier to learning. As such, I am more than happy to use a sliding scale for rates when monetary constraints necessitate it. If you have financial concerns that would impact your ability to study, please email me <Link href = "/contact" color = "teal.500" textDecorationLine = "underline">here</Link>. I am confident that we can figure something out.
                </Text>
            </Box>
        </div>
    );
};
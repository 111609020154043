import { Box, Heading } from '@chakra-ui/react';

import Navbar from './Navbar';
import UpcomingConcerts from './UpcomingConcerts';

export default function Events() {
    return (
        <div className = "container">
            <Navbar />
            <Box m = {8}>
            <Heading align = "center">Upcoming Performances</Heading>
            <Box display = "flex" justifyContent = "center">
            <UpcomingConcerts />
            </Box>
            </Box>
        </div>
    );
};
import { Button, Card, CardHeader, CardBody, Heading, Text } from '@chakra-ui/react';

export default function ConcertCard(props) {
    const { title, description, link, date, time } = props;
    return (
        <Card m = {4}>
        <CardHeader><Heading size = "md">{ title }</Heading></CardHeader>
            <CardBody>
           <Text fontWeight = "bold">{ date }, { time }</Text>
                <Text>{ description }</Text>
            </CardBody>
            {link && <Button as = "a" href = {link} target = "_blank" m = {4}>More Info/Tickets</Button>}
        </Card>
    );
};
import { Box, Link, Heading, Text } from '@chakra-ui/react';

import Carousel from './Carousel';
import Navbar from './Navbar';

export default function Home() {
    return (
        <div className = "container">
            <Navbar />
            <Box m = {8}>
            <Heading align = "center">Welcome!</Heading>
            <Text m = {4}>
                Welcome to my humble home on the web! My name is Ben Fryxell, and I have the great privilege
                of being a professional cellist. I currently serve as <strong>Associate Principal Cello</strong> of 
                the <strong>Jacksonville Symphony</strong>, and I maintain an active <strong>teaching</strong> career, too. I cordially
                invite you to have a look around, and 
                to <Link href= "/contact" color = "teal.500" textDecorationLine = "underline">get in touch</Link> if you need a cellist for something!
            </Text>
            <Carousel />
            </Box>
        </div>
    );
};
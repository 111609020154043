import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
  
  export default function MusicLessonPricing() {
    return (
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Time</Th>
            <Th>1 Lesson</Th>
            <Th>Package of 5</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>30 Min.</Td>  
            <Td>$50</Td>
            <Td>$235</Td>
          </Tr>
          <Tr>
            <Td>45 Min.</Td>
            <Td>$65</Td>
            <Td>$310</Td>  
          </Tr>
          <Tr>
            <Td>60 Min.</Td>
            <Td>$80</Td> 
            <Td>$375</Td>
          </Tr>
        </Tbody>
      </Table>
    );
  };
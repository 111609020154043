import { useEffect, useState } from 'react';

import { Box, Image } from '@chakra-ui/react'

export default function Carousel() {
  const images = [
    '1.jpg', 
    '2.jpg',
    '3.jpg',
    '4.jpg' 
  ];

  const alt = [
    'With James Ehnes and Michael Tilson Thomas after playing principal for the Stravinsky Violin Concerto.',
    'Backstage at New World Center with Miguel Harth-Bedoya after my first principal experience with New World Symphony.',
    'With Emanuel Ax after a Brahms reading.',
    'Post-concert celebration with Kaija Saariaho after playing a chamber piece of hers with friends.'
  ];

  const [currentImage, setCurrentImage] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage(prev => 
        prev === images.length - 1 ? 0 : prev + 1  
      )
    }, 4500)
    return () => clearInterval(interval)
  }, [images.length])

  return (
    <Box maxW = "600px" mx = "auto">
      <Image src = {images[currentImage]} alt = {alt[currentImage]}/>
    </Box>
  );
};
import { Box, Heading, Text } from '@chakra-ui/react';

import Navbar from './Navbar';

export default function SubmissionError() {
    return (
        <div className = "container">
            <Navbar />
            <Box m = {8}>
            <Heading align = "center">Error</Heading>
            <Text m = {4}>
                There was an issue that prevented the message being sent. Please try again with the "Contact" link in the navigation bar.
            </Text>
            </Box>
        </div>
    );
};